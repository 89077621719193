import * as React from 'react';
import { Grid } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { FilterContainer, FilterInput, FilterItem, Label } from '../styledComponents';
import { BaseFilter } from './baseFilter';

interface NetWorthFilterProps {
  onEnter: any;
  filters: any;
  setFiltersState: any;
}

const NetWorthFilter: React.FunctionComponent<NetWorthFilterProps> = ({ onEnter, filters, setFiltersState }) => {
  return (
    <BaseFilter title="Net Worth Estimate">
      <Grid container direction="column" justifyContent="space-between">
        <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Min Assets</Label>
            <FilterInput
              id="minAssets"
              placeholder="0"
              InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ), sx: { height: 30 } }}
              variant="outlined"
              value={filters.minAssets || ''}
              onChange={e => setFiltersState({ minAssets: e.target.value.length === 0 ? null : parseFloat(e.target.value)})}
              onKeyPress={ev => onEnter(ev)}
              type="number"
            />
          </FilterItem>
          <FilterItem item>
            <Label>Max Assets</Label>
            <FilterInput
              id="maxAssets"
              placeholder="5,000,000"
              InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ), sx: { height: 30 } }}
              variant="outlined"
              value={filters.maxAssets || ''}
              onChange={e => setFiltersState({ maxAssets: e.target.value.length === 0 ? null : parseFloat(e.target.value)})}
              onKeyPress={ev => onEnter(ev)}
              type="number"
            />
          </FilterItem>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Min Debt</Label>
            <FilterInput
              id="minDebt"
              placeholder="0"
              InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ), sx: { height: 30 } }}
              variant="outlined"
              value={filters.minDebt || ''}
              onChange={e => setFiltersState({ minDebt: e.target.value.length === 0 ? null : parseFloat(e.target.value)})}
              onKeyPress={ev => onEnter(ev)}
              type="number"
            />
          </FilterItem>
          <FilterItem item>
            <Label>Max Debt</Label>
            <FilterInput
              id="maxDebt"
              placeholder="1,000,000"
              InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ), sx: { height: 30 } }}
              variant="outlined"
              value={filters.maxDebt || ''}
              onChange={e => setFiltersState({ maxDebt: e.target.value.length === 0 ? null : parseFloat(e.target.value)})}
              onKeyPress={ev => onEnter(ev)}
              type="number"
            />
          </FilterItem>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Min Net Worth</Label>
            <FilterInput
              id="minNW"
              placeholder="-2,500,000"
              InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ), sx: { height: 30 } }}
              variant="outlined"
              value={filters.minNetWorth || ''}
              onChange={e => setFiltersState({ minNetWorth: e.target.value.length === 0 ? null : parseFloat(e.target.value)})}
              onKeyPress={ev => onEnter(ev)}
              type="number"
            />
          </FilterItem>
          <FilterItem item>
            <Label>Max Net Worth</Label>
            <FilterInput
              id="maxNW"
              placeholder="5,000,000"
              InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ), sx: { height: 30 } }}
              variant="outlined"
              value={filters.maxNetWorth || ''}
              onChange={e => setFiltersState({ maxNetWorth: e.target.value.length === 0 ? null : parseFloat(e.target.value)})}
              onKeyPress={ev => onEnter(ev)}
              type="number"
            />
          </FilterItem>
        </FilterContainer>
      </Grid>
    </BaseFilter>
  );
};

export { NetWorthFilter };
