import { useEffect } from 'react';
import * as React from 'react';
import { Grid } from '@mui/material';
import { FiltersButton } from '../filtersButton';
import { FilterContainer } from '../styledComponents';
import { BaseFilter } from './baseFilter';
import { usePrevious } from '../../hooks/usePrevious';

interface BehavioralProps {
  onClickButton: any;
  filters: any;
  setFiltersState: any;
}

const BehavioralFilter: React.FunctionComponent<BehavioralProps> = ({ onClickButton, filters, setFiltersState }) => {

  const prevIsMoverShaker = usePrevious(String(filters.isMoverShaker)) || null;
  const prevIsSocialButterfly = usePrevious(String(filters.isSocialButterfly)) || null;
  const prevIsSteadyEddy = usePrevious(String(filters.isSteadyEddy)) || null;
  const prevIsRuleFollower = usePrevious(String(filters.isRuleFollower)) || null;



  useEffect(() => {
    if (
      String(prevIsMoverShaker) !== String(filters.isMoverShaker) ||
      String(prevIsSocialButterfly) !== String(filters.isSocialButterfly) ||
      String(prevIsSteadyEddy) !== String(filters.isSteadyEddy) ||
      String(prevIsRuleFollower) !== String(filters.isRuleFollower)

    ) {
      onClickButton();
    }
  }, [
    filters,
    prevIsMoverShaker,
    prevIsSocialButterfly,
    prevIsSteadyEddy,
    prevIsRuleFollower,
    onClickButton,
  ]);

  return (
    <BaseFilter title={'Behavioral Filter'}>
      <Grid container direction="row" justifyContent="flex-start">
        <FilterContainer container direction="row" justifyContent="space-between">
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.isMoverShaker === true}
                onClick={() => {
                  filters.isMoverShaker !== true
                    ? setFiltersState({ isMoverShaker: true })
                    : setFiltersState({ isMoverShaker: null });
                }}
              >
                Mover and Shaker
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="space-between">
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.isSocialButterfly === true}
                onClick={() => {
                  filters.isSocialButterfly !== true
                    ? setFiltersState({ isSocialButterfly: true })
                    : setFiltersState({ isSocialButterfly: null });
                }}
              >
                Social Butterfly
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="space-between">
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.isSteadyEddy === true}
                onClick={() => {
                  filters.isSteadyEddy !== true
                    ? setFiltersState({ isSteadyEddy: true })
                    : setFiltersState({ isSteadyEddy: null });
                }}
              >
                Steady Eddy
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="space-between">
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.isRuleFollower === true}
                onClick={() => {
                  filters.isRuleFollower  !== true
                    ? setFiltersState({ isRuleFollower: true })
                    : setFiltersState({ isRuleFollower: null });
                }}
              >
                Rule Follower
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
      </Grid>
    </BaseFilter>
  );
};

export { BehavioralFilter };
