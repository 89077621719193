import { useEffect } from 'react';
import * as React from 'react';
import { Grid } from '@mui/material';
import { FiltersButton } from '../filtersButton';
import { Label, FilterContainer, FilterItem } from '../styledComponents';
import { BaseFilter } from './baseFilter';
import { usePrevious } from '../../hooks/usePrevious';

interface RetirementTraitsProps {
  onClickButton: any;
  filters: any;
  setFiltersState: any;
}

//TODO Connect remaining filters
const RetirementFilter: React.FunctionComponent<RetirementTraitsProps> = ({ onClickButton, filters, setFiltersState }) => {

  const prevRetirementSaver = usePrevious(String(filters.isRetirementSaver)) || null;
  const prevHasRetirementSavingsAcct = usePrevious(String(filters.hasRetirementSavingsAcct)) || null;
  const prevNeedsPortfolioReview = usePrevious(String(filters.needsPortfolioReview)) || null;

  useEffect(() => {
    if (  
      String(prevRetirementSaver) !== String(filters.isRetirementSaver) ||
      String(prevHasRetirementSavingsAcct) !== String(filters.hasRetirementSavingsAcct) ||
      String(prevNeedsPortfolioReview) !== String(filters.needsPortfolioReview)
    ) {
      onClickButton();
    }
  }, [
    filters,
    prevRetirementSaver,
    prevHasRetirementSavingsAcct,
    prevNeedsPortfolioReview,
    onClickButton,
  ]);

  return (
    <BaseFilter title={'Retirement'}>
      <Grid container direction="row" justifyContent="flex-start">
      <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Retirement Saver</Label>
          </FilterItem>
          <Grid container direction="row" justifyContent="space-between">
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.isRetirementSaver === true}
                onClick={() => {
                  filters.isRetirementSaver !== true
                    ? setFiltersState({ isRetirementSaver: true })
                    : setFiltersState({ isRetirementSaver: null });
                }}
              >
                Yes
              </FiltersButton>
            </FilterItem>
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.isRetirementSaver === false}
                onClick={() => {
                  filters.isRetirementSaver !== false
                    ? setFiltersState({ isRetirementSaver: false })
                    : setFiltersState({ isRetirementSaver: null });
                }}
              >
                No
              </FiltersButton>
            </FilterItem>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="flex-start">
          <Grid item>
            <Label>Retirement Savings Account</Label>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <FiltersButton
                sm
                variant="outlined"
                active={filters.hasRetirementSavingsAcct === true}
                onClick={() => {
                  filters.hasRetirementSavingsAcct !== true
                    ? setFiltersState({ hasRetirementSavingsAcct: true })
                    : setFiltersState({ hasRetirementSavingsAcct: null });
                }}
              >
                Has
              </FiltersButton>
            </Grid>
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.hasRetirementSavingsAcct === false}
                onClick={() => {
                  filters.hasRetirementSavingsAcct === false
                    ? setFiltersState({ hasRetirementSavingsAcct: null })
                    : setFiltersState({ hasRetirementSavingsAcct: false });
                }}
              >
                Doesn't Have
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
      </Grid>
    </BaseFilter>
  );
};

export { RetirementFilter };
