import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import {Grid, ListItemText} from '@mui/material';
import { colors } from '../constants/colors';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

const StyledExpansionPanel = styled(Accordion)`
  &.MuiExpansionPanel-root:before {
    height: 0;
  }

  &.MuiExpansionPanel-root {
    box-shadow: none;
    border-top: 0;
    border-bottom: 1px solid ${colors.gray2};

    .MuiExpansionPanelSummary-content {
      margin: 27px 0 22px 0;
    }
  }

  &.MuiExpansionPanel-root.Mui-expanded {
    margin: 0;
    box-shadow: none;
    border-top: 0;
  }

  & .MuiExpansionPanelDetails-root {
    padding: 0;
    border-top: 0;
    box-shadow: none;
  }
`;

const FiltersExpansionPanel = styled(Accordion)`
  && {
    background-color: ${colors.gray3};
  }
  &.MuiExpansionPanel-root:before {
    height: 0;
  }

  &.MuiExpansionPanel-root {
    box-shadow: none;
    border-top: 0;
    border-bottom: 1px solid ${colors.gray2};

    .MuiExpansionPanelSummary-content {
      margin: 27px 0 22px 0;
    }
  }

  &.MuiExpansionPanel-root.Mui-expanded {
    margin: 0;
    box-shadow: none;
    border-top: 0;
  }

  & .MuiExpansionPanelDetails-root {
    padding: 0;
    border-top: 0;
    box-shadow: none;
  }

  & .MuiExpansionPanelSummary-root {
    padding: 0;
  }
`;

const GridBorderBottom = styled(Grid)`
  border-bottom: 1px solid ${colors.gray2};
  padding: 15px 5px 15px 5px;
`;

const GridNoBorder = styled(GridBorderBottom)`
  border-bottom: none;
`;

const StyledGridBorderBottom = styled(GridBorderBottom)`
  &:last-of-type {
    border-bottom: 0;
  }
`;

const GridBorderTop = styled(Grid)`
  border-top: 1px solid ${colors.gray2};
  padding: 15px 5px 15px 5px;
`;

const SummaryContainer = styled(Grid)`
  background-color: ${colors.gray1};
  padding: 0 24px;
`;

const SectionContainer = styled(Grid)`
  background-color: ${colors.white};
  padding: 0 24px 10px 24px;
  &:last-of-type {
    padding: 0 24px 29px 24px;
  }
`;

const SectionTitle = styled.p`
  color: ${colors.blue2};
  margin: 0;
  font-size: 14px;
  font-weight: bold;
`;

const Label = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  color: ${colors.blue2};
`;

const SubLabel = styled.p`
  margin: 0;
  color: ${colors.blue2};
`;

const GridLabel = styled(Grid)`
  width: 70%;
`;

const TitleContainer = styled.div`
  background-color: ${colors.gray1};
  padding-left: 31px;
`;

const VerticalDivider = styled(Divider)`
  && {
    width: 2px;
  }
`;

const DisabledText = styled.p`
  color: ${colors.blue6};
  margin: 4px 0 0 0;
  font-size: 16px;
  font-weight: bold;
`;

const FilterItem = styled(Grid)`
  max-width: 125px;
`;

const FilterContainer = styled(Grid)`
  margin-bottom: 15px;
  max-width: 250px;
`;

const FilterListItemText = styled(ListItemText)`
  max-width: 200px;
  
`;
const FilterInput = styled(TextField)`
  background-color: ${colors.white};

  && {
    border-radius: 5px;
    margin-top: 8px;
  }

  & .MuiInputBase-root.Mui-disabled {
    background-color: ${colors.gray1};
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 10px;
  }
  

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const FilterFormControl = styled(FormControl)`
  && {
    width: 100%;
    height: 54px;
    border-radius: 10px;
    border: 0;
    margin-top: 12px;
    background-color: ${props => (props.disabled ? colors.gray1 : colors.white)};
    ${props => (props.disabled ? 'border: 1px solid rgba(0, 0, 0, 0.26);' : '')}
  }

  & .MuiInputLabel-outlined {
    color: ${colors.blue2};
  }

  & .MuiInput-underline:before {
    border: 0;
    transition: none;
  }

  & .MuiInput-underline:after {
    border: 0;
    transition: none;
  }

  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: 0;
  }

  & .MuiSelect-selectMenu {
    padding-left: 16px;
    color: ${colors.blue2};
  }

  & .MuiSelect-select:focus {
    background-color: transparent;
  }

  & .MuiSelect-icon {
    color: ${colors.blue2};
    font-size: 32px;
    top: 0;//calc(50% - 17px);
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiListItem-button {
    && {
      background-color: ${colors.white};
      :hover {
        background-color: ${colors.gray3};
      }

      & .MuiListItemText-root {
        color: ${colors.blue2};
      }

      &.highlight {
        background-color: ${colors.gray2};
      }

      &&.Mui-focusVisible {
        background-color: ${colors.gray2};
      }

      &.Mui-selected {
        background-color: ${colors.gray2};
        &.any-highlighted {
          background-color: ${colors.white};
        }

        &.highlight {
          background-color: ${colors.gray2};
        }
      }
    }
  }
`;

export {
  DisabledText,
  StyledExpansionPanel,
  FiltersExpansionPanel,
  GridBorderBottom,
  GridNoBorder,
  StyledGridBorderBottom,
  GridBorderTop,
  SummaryContainer,
  SectionContainer,
  SectionTitle,
  Label,
  GridLabel,
  SubLabel,
  TitleContainer,
  VerticalDivider,
  FilterItem,
  FilterListItemText,
  FilterContainer,
  FilterFormControl,
  FilterInput,
  StyledMenuItem,
};
