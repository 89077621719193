export const usStates = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
];

export const badgeAwarded = [
    'Setup Badge',
    'Snapshot Badge',
    'Budget Badge',
    'Debt Badge',
    'Insurance Badge',
    'Retirement Badge',
    'Investment Badge',
    'Tax Planning Badge',
    'Estate and Wills Badge',
    'Education Badge',
    'New Account Badge',
    'New To-Do Badge',
    'To-Do Complete Badge',
    'Profile Badge',
    'Article Badge',
    'Flashcard Badge',
    'Linked Account Badge',
    'Calculator Badge',
];

export const initialFiltersState = {
    // behavioral
    isMoverShaker: null,
    isSocialButterfly: null,
    isSteadyEddy: null,
    isRuleFollower: null,
    // institutions
    institution: "0",
    // user traits
    states: [],
    hasChildren: null,
    hasDependents: null,
    isHomeowner: null,
    isVehicleOwner: null,
    // badges
    badges: [],
    // todos
    minTodos: null,
    maxTodos: null,
    todoByName: null,
    // net worth estimate
    minAssets: null,
    maxAssets: null,
    minDebt: null,
    maxDebt: null,
    minNetWorth: null,
    maxNetWorth: null,
    // budget
    minIncome: null,
    maxIncome: null,
    ltTotalBudget: null,
    gtTotalBudget: null,
    ltCashFund: null,
    gtCashFund: null,
    hasCashReserve: null,
    hasEmergencyFund: null,
    hasCompletedBudget: null,
    overBudgetInPlanning: null,
    recommendationsUsed: null,
    // insurance
    needsLifeInsurance: null,
    needsDisabilityCoverage: null,
    needsUmbrellaCoverage: null,
    needsReviewInsurance: null,
    // retirement
    hasRetirementSavingsAcct: null,
    isRetirementSaver: null,
    // investments
    isActiveInvestor: null,
    hasAlternativeInvestmentIncome: null,
    needsPortfolioReview: null,
    isWorkingWithPlanner: null,
    // estate planning
    hasWills: null,
    hasGuardianship: null,
    needsReviewEstate: null,
    // college savings plans
    hasSavingsPlans: null,
    // tax profile
    alternativeMinTax: null,

    // unused currently
    // ltLivingExpense: null,
    // gtLivingExpense: null,
    // minCreditCardDebt: null,
    // maxCreditCardDebt: null,
    // minStudentLoanDebt: null,
    // maxStudentLoanDebt: null,
    // minMortgageDebt: null,
    // maxMortgageDebt: null,
    // minAutoLoanDebt: null,
    // maxAutoLoanDebt: null,
    // minPersonalLoanDebt: null,
    // maxPersonalLoanDebt: null,
    // minTotalSavings: null,
    // maxTotalSavings: null,

};
