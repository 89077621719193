// resources
import {useEffect, useRef, useState} from 'react';

import * as React from 'react';
import {useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import {TableContainer, Table, TableHead, TableBody, TableRow, TablePagination, Paper, Box} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {DashboardActionCreators, DashboardState} from '../store/dashboard';
import {ApplicationState} from '../store';
import {colors} from '../constants/colors';
import history from '../services/history';
import {MailTo} from "../models/MailTo";
import adminApi from "../services/adminApi";


const Title = styled.h1`
  color: ${colors.blue4};
  align-self: flex-start;
  margin: 0;
  font-size: 24px;
`;

const NameText = styled(Typography)`
  && {
    font-weight: bold;
    color: ${colors.blue2};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ActivityText = styled(Typography)`
  && {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const InstitutionText = styled(Typography)`
  && {
    font-weight: lighter;
    color: ${colors.blue1};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const HeaderTableRow = styled(TableRow)({

    backgroundColor: colors.blue4,
    color: colors.blue2,
    borderRadius: 10,
    fontWeight: "bolder"

})


const StyledButton = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    font-weight: normal;
    color: ${colors.blue4};
    background-color: ${colors.gray1};
    border-color: ${colors.blue4};
    border-radius: 5px;
  }
`;

const StyledButtonCSV = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    font-weight: normal;
    color: ${colors.blue4};
    background-color: ${colors.gray1};
    border-color: ${colors.blue4};
    border-radius: 5px;
  }
`;

interface LocationState {
    from: {
        pathname: string;
    }
    page: number;
}

function MailLink({ email, subject, body, username }: MailTo) {
    return (
        <NameText color="textPrimary">
            <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}  >
                {username}
            </a>
        </NameText>
    );
}

type OwnProps = DashboardState & typeof DashboardActionCreators;

const DashboardPage = ({data, meta, filters, filterUsers} : OwnProps) => {
    const {state} = useLocation<LocationState>();
    const [page, setPage] = useState<number>(state ? state.page : 0);
    const [csvData, setCsvData] = useState<[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const didMount = useRef(false);

    useEffect(() => {
        filterUsers(page, filters);
    }, [page, filterUsers]);

    useEffect(() => {
        filterUsers(0, filters);
    }, [filterUsers, filters]);

    useEffect( () => {
        if (!didMount.current) {
            didMount.current = true;
        } else {
            setTimeout(() => {
                if (csvLinkRef.current) csvLinkRef.current.link.click();
            });
            setLoading(false);
        }
    }, [csvData]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const getFilteredUsers = () => {
        adminApi.defaults.headers.Authorization = localStorage.getItem('token');
        return adminApi.post('users', filters).then(res => res.data.data)
    }
    const downloadCSV = async () => {
        setLoading(true);
        const filteredUserData = await getFilteredUsers();

        setCsvData(filteredUserData);
        setLoading(false);
    }

    // @ts-ignore
    return (
        <TableContainer sx={{margin: 0, padding: 1}} component={Paper}>
            <Box p={1} display="flex">
                <Box p={1} flexGrow={1}>
                    <Title>Pocketnest Users</Title>
                </Box>
                <Box p={1}>
                    <StyledButtonCSV
                        variant="outlined"
                        onClick={downloadCSV}
                        disabled={loading}
                    >{loading ? 'Loading...' : 'Export to CSV'}</StyledButtonCSV >
                    <CSVLink
                        filename="Pocketnest Users.csv"
                        data={csvData}
                        ref={csvLinkRef}>
                    </CSVLink>
                </Box>
            </Box>

            <Table sx={{minWidth: 650, margin: 2}} aria-label="simple table">
                <TableHead>
                    <HeaderTableRow>
                        <TableCell sx={{color: "#EEE", fontWeight: "bolder", fontSize: "large", minWidth: 55}} align="left">Name/Institution</TableCell>
                        <TableCell sx={{color: "#EEE", fontWeight: "bolder", fontSize: "large", minWidth: 35}} align="center">Behavioral Tendency</TableCell>
                        <TableCell sx={{color: "#EEE", fontWeight: "bolder", fontSize: "large", minWidth: 35}} align="center">Activity</TableCell>
                        <TableCell sx={{color: "#EEE", fontWeight: "bolder", fontSize: "large", minWidth: 60}} align="center">Details</TableCell>
                    </HeaderTableRow>
                </TableHead>
                <TableBody>
                    {data ? (
                        data.map(user => (
                            <TableRow key={user.id}>
                                <TableCell>
                                    <MailLink email={user.email} subject="{ insert subject here }" body="{ insert email body here }" username={user.first_name ? user.first_name + ' ' + user.last_name : user.email} />
                                    <InstitutionText>{user.institution_name}</InstitutionText>
                                </TableCell>
                                <TableCell align="center">
                                    <ActivityText>{user.behavioral_tendency}</ActivityText>
                                </TableCell>
                                <TableCell align="center">
                                    <ActivityText>User
                                        since: {new Date(user.joined!).toLocaleDateString()}</ActivityText>
                                    <ActivityText>Badges
                                        earned: {user.badges_earned}</ActivityText>
                                </TableCell>
                                <TableCell align="center">
                                    <StyledButton
                                        variant="outlined"
                                        onClick={() => history.push({
                                            pathname: `user/${user.id}/summary_data`,
                                            state: {page: page}
                                        })}
                                    >User Summary</StyledButton>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <Title>No users were found.</Title>
                    )}
                </TableBody>
            </Table>
            {meta ? (
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[meta.per_page]}
                    colSpan={3}
                    count={meta.total}
                    rowsPerPage={meta.per_page}
                    page={page}
                    onPageChange={handleChangePage}
                />
            ) : null}
        </TableContainer>
    );
};

export default connect(
    (state: ApplicationState) => state.dashboard,
    DashboardActionCreators,
)(DashboardPage as any);
