import * as React from 'react';
import '@reach/tabs/styles.css';
import InputAdornment from '@mui/material/InputAdornment';
import { Grid } from '@mui/material';
import { BaseFilter } from './baseFilter';
import { FilterContainer, FilterItem, Label, FilterInput } from '../styledComponents';
import {FiltersButton} from "../filtersButton";



interface BudgetFilterProps {
  onEnter: any;
  filters: any;
  setFiltersState: any;
}

const BudgetFilter: React.FunctionComponent<BudgetFilterProps> = ({
  onEnter,
  filters,
  setFiltersState,
}) => {


  return (
    <BaseFilter title="Budget">
      <Grid container direction="column" justifyContent="space-between">
        <Label>Net Income</Label>
        <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Min Monthly</Label>
            <FilterInput
              id="minIncome"
              placeholder="0"
              InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ), sx: { height: 30 } }}
              variant="outlined"
              value={filters.minIncome || ''}
              onChange={e => setFiltersState({ minIncome: e.target.value.length === 0 ? null : parseFloat(e.target.value)})}
              type="number"
            />
          </FilterItem>
          <FilterItem item>
            <Label>Max Monthly</Label>
            <FilterInput
              id="maxIncome"
              placeholder="10,000"
              InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ), sx: { height: 30 } }}
              variant="outlined"
              value={filters.maxIncome || ''}
              onChange={e => setFiltersState({ maxIncome: e.target.value.length === 0 ? null : parseFloat(e.target.value) })}
              type="number"
            />
          </FilterItem>
        </FilterContainer>

        <Label>Living Expenses</Label>
        <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Greater than</Label>
            <FilterInput
                id="gtLivingExpense"
                placeholder="0"
                InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ), sx: { height: 30 } }}
                variant="outlined"
                value={filters.gtLivingExpense || ''}
                onChange={e => setFiltersState({ gtLivingExpense: e.target.value.length === 0 ? null : parseFloat(e.target.value) })}
                type="number"
            />
          </FilterItem>
          <FilterItem item>
            <Label>Less than</Label>
            <FilterInput
                id="ltLivingExpense"
                placeholder="5,000"
                InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ), sx: { height: 30 } }}
                variant="outlined"
                value={filters.ltLivingExpense || ''}
                onChange={e => setFiltersState({ ltLivingExpense: e.target.value.length === 0 ? null : parseFloat(e.target.value) })}
                type="number"
            />
          </FilterItem>
        </FilterContainer>

        <Label>Total Budget</Label>
        <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Greater than</Label>
            <FilterInput
                id="gtTotalBudget"
                placeholder="0"
                InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ), sx: { height: 30 } }}
                variant="outlined"
                value={filters.gtTotalBudget || ''}
                onChange={e => setFiltersState({ gtTotalBudget: e.target.value.length === 0 ? null : parseFloat(e.target.value) })}
                type="number"
            />
          </FilterItem>
          <FilterItem item>
            <Label>Less than</Label>
            <FilterInput
                id="ltTotalBudget"
                placeholder="5,000"
                InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ), sx: { height: 30 } }}
                variant="outlined"
                value={filters.ltTotalBudget || ''}
                onChange={e => setFiltersState({ ltTotalBudget: e.target.value.length === 0 ? null : parseFloat(e.target.value) })}
                type="number"
            />
          </FilterItem>
        </FilterContainer>

        <Label>Cash Fund</Label>
        <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Greater than</Label>
            <FilterInput
                id="gtCashFund"
                placeholder="501"
                InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ), sx: { height: 30 } }}
                variant="outlined"
                value={filters.gtCashFund || ''}
                onChange={e => setFiltersState({ gtCashFund: e.target.value.length === 0 ? null : parseFloat(e.target.value) })}
                type="number"
            />
          </FilterItem>
          <FilterItem item>
            <Label>Less than</Label>
            <FilterInput
                id="ltCashFund"
                placeholder="5,000"
                InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ), sx: { height: 30 } }}
                variant="outlined"
                value={filters.ltCashFund || ''}
                onChange={e => setFiltersState({ ltCashFund: e.target.value.length === 0 ? null : parseFloat(e.target.value) })}
                type="number"
            />
          </FilterItem>
        </FilterContainer>

        <Label>Has Reserve Fund</Label>
        <FilterContainer container direction="row" justifyContent="space-between">
          <Grid container direction="row" justifyContent="space-between">
            <FilterItem item>
              <FiltersButton
                  variant="outlined"
                  active={filters.hasCashReserve === true}
                  onClick={() => {
                    filters.hasCashReserve !== true
                        ? setFiltersState({ hasCashReserve: true })
                        : setFiltersState({ hasCashReserve: null });
                  }}
              >
                Yes
              </FiltersButton>
            </FilterItem>
            <FilterItem item>
              <FiltersButton
                  variant="outlined"
                  active={filters.hasCashReserve === false}
                  onClick={() => {
                    filters.hasCashReserve !== false
                        ? setFiltersState({ hasCashReserve: false })
                        : setFiltersState({ hasCashReserve: null });
                  }}
              >
                No
              </FiltersButton>
            </FilterItem>
          </Grid>
        </FilterContainer>

        <Label>Has Emergency Fund</Label>
        <FilterContainer container direction="row" justifyContent="space-between">
          <Grid container direction="row" justifyContent="space-between">
            <FilterItem item>
              <FiltersButton
                  variant="outlined"
                  active={filters.hasEmergencyFund === true}
                  onClick={() => {
                    filters.hasEmergencyFund !== true
                        ? setFiltersState({ hasEmergencyFund: true })
                        : setFiltersState({ hasEmergencyFund: null });
                  }}
              >
                Yes
              </FiltersButton>
            </FilterItem>
            <FilterItem item>
              <FiltersButton
                  variant="outlined"
                  active={filters.hasEmergencyFund === false}
                  onClick={() => {
                    filters.hasEmergencyFund !== false
                        ? setFiltersState({ hasEmergencyFund: false })
                        : setFiltersState({ hasEmergencyFund: null });
                  }}
              >
                No
              </FiltersButton>
            </FilterItem>
          </Grid>
        </FilterContainer>

        <Label>Has Completed Budget</Label>
        <FilterContainer container direction="row" justifyContent="space-between">
          <Grid container direction="row" justifyContent="space-between">
            <FilterItem item>
              <FiltersButton
                  variant="outlined"
                  active={filters.hasCompletedBudget === true}
                  onClick={() => {
                    filters.hasCompletedBudget !== true
                        ? setFiltersState({ hasCompletedBudget: true })
                        : setFiltersState({ hasCompletedBudget: null });
                  }}
              >
                Yes
              </FiltersButton>
            </FilterItem>
            <FilterItem item>
              <FiltersButton
                  variant="outlined"
                  active={filters.hasCompletedBudget === false}
                  onClick={() => {
                    filters.hasCompletedBudget !== false
                        ? setFiltersState({ hasCompletedBudget: false })
                        : setFiltersState({ hasCompletedBudget: null });
                  }}
              >
                No
              </FiltersButton>
            </FilterItem>
          </Grid>
        </FilterContainer>

        <Label>Over Budget in Planning</Label>
        <FilterContainer container direction="row" justifyContent="space-between">
          <Grid container direction="row" justifyContent="space-between">
            <FilterItem item>
              <FiltersButton
                  variant="outlined"
                  active={filters.overBudgetInPlanning === true}
                  onClick={() => {
                    filters.overBudgetInPlanning !== true
                        ? setFiltersState({ overBudgetInPlanning: true })
                        : setFiltersState({ overBudgetInPlanning: null });
                  }}
              >
                Yes
              </FiltersButton>
            </FilterItem>
            <FilterItem item>
              <FiltersButton
                  variant="outlined"
                  active={filters.overBudgetInPlanning === false}
                  onClick={() => {
                    filters.overBudgetInPlanning !== false
                        ? setFiltersState({ overBudgetInPlanning: false })
                        : setFiltersState({ overBudgetInPlanning: null });
                  }}
              >
                No
              </FiltersButton>
            </FilterItem>
          </Grid>
        </FilterContainer>

        <Label>Recommendations Used?</Label>
        <FilterContainer container direction="row" justifyContent="space-between">
          <Grid container direction="row" justifyContent="space-between"  >
            <FilterItem item>
              <FiltersButton
                  variant="outlined"
                  active={filters.recommendationsUsed === true}
                  onClick={() => {
                    filters.recommendationsUsed !== true
                        ? setFiltersState({ recommendationsUsed: true })
                        : setFiltersState({ recommendationsUsed: null });
                  }}
              >
                Yes
              </FiltersButton>
            </FilterItem>
            <FilterItem item>
              <FiltersButton
                  variant="outlined"
                  active={filters.recommendationsUsed === false}
                  onClick={() => {
                    filters.recommendationsUsed !== false
                        ? setFiltersState({ recommendationsUsed: false })
                        : setFiltersState({ recommendationsUsed: null });
                  }}
              >
                No
              </FiltersButton>
            </FilterItem>
          </Grid>
        </FilterContainer>

      </Grid>
    </BaseFilter>
  );
};

export { BudgetFilter };
